var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"down-top-padding",attrs:{"fluid":""}},[_c('BaseBreadcrumb',{attrs:{"breadcrumbs":_vm.breadcrumbs,"icon":_vm.page.icon,"title":_vm.page.title}}),_c('v-card',[_c('v-card-text',{staticClass:"pa-5"},[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('v-text-field',{attrs:{"background-color":"transparent","filled":"","hide-details":"","label":"Поиск...","name":"title","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[(_vm.search)?_c('v-btn',{staticClass:"reset-button",attrs:{"slot":"append","color":"red","icon":""},on:{"click":function($event){_vm.search = null}},slot:"append"},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e()],1)],1),_c('v-col',[_c('v-switch',{attrs:{"label":"Показать удаленные","name":"only_trashed","value":"1"},model:{value:(_vm.only_trashed),callback:function ($$v) {_vm.only_trashed=$$v},expression:"only_trashed"}})],1),_c('v-col',{staticClass:"justify-end align-center d-flex",attrs:{"cols":"12","lg":"4"}},[_c('v-dialog',{attrs:{"max-width":"700px","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{attrs:{"color":"primary","to":"/reviews/create"}},[_vm._v(" Создать отзыв ")])]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"options":_vm.options,"search":_vm.search,"server-items-length":_vm.total},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"pill":""}},[_vm._v(_vm._s(item.id))])]}},{key:"item.published",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.published ? 'Да' : 'Нет'))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.deleted_at)?_c('v-icon',{attrs:{"color":"green","small":""},on:{"click":function($event){return _vm.restoreItem(item)}}},[_vm._v(" mdi-undo ")]):[_c('v-btn',{attrs:{"href":_vm.editItem(item),"icon":""}},[_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v(" mdi-pencil ")])],1),_c('v-icon',{attrs:{"color":"red","small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }