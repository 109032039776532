<template>
  <v-form>
    <v-autocomplete
      v-if="users"
      v-model="form.user_id"
      :error-messages="errors.user_id"
      :items="users"
      item-text="full_name"
      item-value="id"
      label="Выберите пользователя"
      name="user_id"
      required
    />

    <v-text-field
      v-model="form.user_name"
      :error-messages="errors.user_name"
      label="ФИО пользователя (при отсутствии в списке)"
      name="user_name"
    />

    <v-text-field
      v-model="form.title"
      :error-messages="errors.title"
      label="Заголовок"
      name="title"
    />

    <v-textarea
      v-model="form.text"
      :error-messages="errors.text"
      label="Текст отзыва"
      name="text"
      required
    />

    <div>
      <v-file-input
        v-model="form.file"
        label="Файл"
        name="file"
      />
      <img
        v-if="form.file_path"
        :src="form.file_path"
        alt="Preview"
        class="mt-2"
        style="max-width: 200px; max-height: 200px;"
      />
    </div>

    <div>
      <v-file-input
        v-model="form.custom_user_image"
        :error-messages="errors.custom_user_image"
        accept="image/*"
        label="Изображение"
        name="custom_user_image"
      />
      <img
        v-if="form.user_image_path"
        :src="form.user_image_path"
        alt="Preview"
        class="mt-2"
        style="max-width: 200px; max-height: 200px;"
      />
    </div>

    <v-checkbox
      v-model="form.published"
      label="Опубликовано"
      name="published"
    />

    <v-slider
      v-model="form.rating"
      :error-messages="errors.rating"
      label="Рейтинг"
      max="5"
      min="1"
      name="rating"
      step="1"
      thumb-label
      ticks="false"
    />

    <v-btn
      :disabled="isSaveDisabled"
      :loading="isSaveDisabled"
      class="mr-4"
      color="primary"
      @click="submit(true)"
    >
      Сохранить
    </v-btn>

    <v-btn
      :disabled="isSaveDisabled"
      :loading="isSaveDisabled"
      class="mr-4"
      color="primary"
      @click="submit(false)"
    >
      Сохранить и продолжить
    </v-btn>
  </v-form>
</template>

<script>
import {User} from "@/models/user";

export default {
  name: 'ReviewForm',
  props: {
    review: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: Object,
      required: true,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    form: {
      id: null,
      title: '',
      text: null,
      user_id: null,
      file: null,
      custom_user_image: null,
      entity_id: null,
      entity_type: null,
      user_name: null,
      published: 0,
      rating: 1,
    },
    isSaveDisabled: false,
    types: [],
    users: [],
    filePreview: null,
    imagePreview: null,
  }),
  created() {
    this.getUsers();
  },
  methods: {
    async submit(isNeedRedirect) {
      this.isSaveDisabled = true;

      const formData = new FormData();

      for (const key in this.form) {
        if (this.form.hasOwnProperty(key)) {
          formData.append(key, key === 'published' ? Number(this.form[key]) : this.form[key]);
        }
      }

      const file = this.form.custom_user_image;
      if (file && !file.type.startsWith('image/')) {
        this.errors.custom_user_image = 'Только изображения разрешены.';
        this.isSaveDisabled = false;
        return;
      }

      this.$emit('onSubmit', {
        formData: formData,
        isNeedRedirect: isNeedRedirect,
      });
    },
    async getUsers() {
      try {
        const users = await User.$query().get(50);
        this.users = users.map(person => person.$attributes);
        this.loading = false;
      } catch (e) {
        throw e;
      }
    },
  },
  watch: {
    review: {
      handler(review) {
        if (review && Object.keys(review).length > 0) {
          this.form = review;
          this.isSaveDisabled = false;
        }
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
