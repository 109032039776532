<template>
  <v-container class="down-top-padding" fluid>
    <BaseBreadcrumb :breadcrumbs="breadcrumbs" :icon="page.icon" :title="page.title"/>
    <v-card>
      <v-card-text class="pa-5">
        <v-row>
          <v-col>
            <h1>Создание отзыва</h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <review-form
              :errors="errors"
              @onSubmit="submit"
            />
          </v-col>
        </v-row>
        <v-snackbar
          v-model="snackbarSuccess"
          :timeout="timeout"
          color="success"
        >
          Сохранено

          <template v-slot:action="{ attrs }">
            <v-btn
              color="red"
              icon
              @click="snackbarSuccess = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
        <v-snackbar
          v-model="snackbarError"
          :timeout="timeout"
          color="error"
        >
          {{ this.errors }}

          <template v-slot:action="{ attrs }">
            <v-btn
              color="red"
              icon
              @click="snackbarError = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import ReviewForm from "@/components/reviews/ReviewForm";
import axiosInstance from "@/axiosInstance";
import BaseBreadcrumb from "@/components/commonComponents/BaseBreadcrumb.vue";

export default {
  name: 'Create',
  components: {BaseBreadcrumb, ReviewForm},
  data: () => ({
    errors: {},
    snackbarSuccess: false,
    snackbarError: false,
    timeout: 2000,
    page: {
      title: "Создание отзывы"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Отзывы",
        disabled: false,
        to: '/reviews'
      },
      {
        text: "Создание",
        disabled: true,
        to: '/reviews/create'
      },
    ],
  }),
  methods: {
    async submit({formData, isNeedRedirect}) {
      try {
        this.errors = {};
        const review = await axiosInstance.post('/reviews', formData);

        this.snackbarSuccess = true;

        if (isNeedRedirect) {
          await this.$router.push({name: 'reviews-index'});
        } else {
          await this.$router.push({name: 'reviews-edit', params: {id: review.data.data.id}});
        }
      } catch (e) {
        if (e.response?.data) {
          this.errors = e.response.data.errors
          this.snackbarError = true;
        }
        throw e;
      }
    },
  },
};
</script>
