
<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row justify="space-between">
          <v-col cols="12" lg="4">
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              label="Поиск..."
              filled
              background-color="transparent"
              hide-details
              name="title"
            >
              <v-btn v-if="search" class="reset-button"
                     slot="append"
                     icon
                     @click="search = null"
                     color="red"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-text-field>
          </v-col>
          <v-col>
            <v-switch
              v-model="only_trashed"
              label="Показать удаленные"
              name="only_trashed"
              value="1"
            ></v-switch>
          </v-col>
          <v-col cols="12" lg="4" class="justify-end align-center d-flex">
            <v-btn v-if="selectedPromocode" color="primary" @click="usersImport"  class="mr-2">
              Выгрузить в csv
            </v-btn>
            <v-dialog v-model="dialog" persistent max-width="700px">
              <template v-slot:activator="{ on }">
                <v-btn color="primary" @click="$router.push({name: 'users-create'})">
                  Создать пользователя
                </v-btn>
              </template>
            </v-dialog>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5">
            <v-select
              :items="promocodes"
              label="Выбрать пользователей промокода"
                item-text="title"
              item-value="id"
              @change="onSelectPromocode"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="items"
              :options.sync="options"
              :server-items-length="totalItems"
              :loading="isLoading"
              :footer-props="{'items-per-page-options':[5, 10, 15, 20, -1]}"
            >
              <template v-slot:item.id="{ item }">
                <v-chip pill>{{ item.id }}</v-chip>
              </template>
              <template v-slot:item.full_name="{ item }">
                {{ item.last_name }} {{ item.first_name }}
              </template>
              <template v-slot:item.autologin="{ item }">
                <v-btn
                  v-if="item.autologin_admin_url"
                  @click="copyLoginUserUrl(item.autologin_client_url)"
                  color="primary"
                  small
                  class="mr-1"
                >
                  <v-icon
                    color="white"
                  >
                    mdi-login
                  </v-icon>
                  Admin
                </v-btn>
                <v-btn
                  v-if="item.autologin_client_url"
                  @click="copyLoginUserUrl(item.autologin_client_url)"
                  color="primary"
                  small
                  class="mr-1"
                >
                  <v-icon
                    color="white"
                  >
                    mdi-login
                  </v-icon>
                  Client
                </v-btn>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn
                  :href="editItem(item)"
                  icon
                >
                  <v-icon color="primary" small>
                    mdi-pencil
                  </v-icon>
                </v-btn>
                <v-icon
                  color="red"
                  @click="deleteItem(item)"
                  small
                >
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import axiosInstance from '../../../axiosInstance';

export default {
  name: "Index",
  data: () => ({
    headers: [
      {text: 'Id', value: 'id'},
      {text: 'ФИО', value: 'full_name'},
      {text: 'Email', value: 'email'},
      {text: 'Последняя авторизация', value: 'latest_login_at'},
      {text: 'Данные онбординга', value: 'onboarding_data'},
      {text: 'Автологин', value: 'autologin'},
      {text: 'Действия', value: 'actions'},
    ],
    options: {
      sortBy: ['id'],
      sortDesc: [true],
      page: 1,
      itemsPerPage: 20,
    },
    total: 0,
    search: '',
    filter: {},
    promocodeUsers: [],
    only_trashed: null,
    page: {
      title: "Пользователи"
    },
    selectedPromocode: false,
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Пользователи",
        disabled: true,
        to: '/users'
      },
    ]
  }),
  created() {
    this.get();
    this.fetchPromocodes();
  },
  computed: {
    ...mapGetters({
      items: 'Users/getFetchedListing',
      totalItems: 'Users/getListingTotalItems',
      isLoading: 'Users/isListingLoad',
      promocodes: 'Users/getFetchedPromocodes',
    }),
  },
  methods: {
    ...mapActions({
      fetchListing: 'Users/fetchListing',
      destroyItem: 'Users/destroy',
      fetchPromocodes: 'Users/fetchPromocodesWithUsers',

    }),
    async get() {
      const payload = {
        options: this.options,
        only_trashed: this.onlyTrashed,
        search: this.search,
        filter: this.filter,
      }

      await this.fetchListing(payload);
    },
    async deleteItem(item) {
      await this.destroyItem(item.id).finally(() => this.get());
    },
    editItem(item) {
      return this.$router.resolve({name: 'users-edit', params: {id: item.id}}).href
    },
    createFile(response, promocode = null){
      let a = document.createElement("a");
      let file = new Blob([response.data], {type: 'text/csv'});
      a.href = URL.createObjectURL(file);
      a.download = promocode + `_promocode_users.csv`;
      a.click();
    },
    copyLoginUserUrl(autologinClientUrl) {
      this.$copyText(autologinClientUrl).then(() => {
        this.$store.dispatch('snackbar/showSuccessCopyFastLoginUserUrl', null, { root: true });
      }, () => {
        this.$store.dispatch('snackbar/showSuccessCopyFastLoginUserUrlError', null, { root: true });
      })
    },
    async usersImport() {

      if(this.promocodeUsers.length)
      {
        await axiosInstance.post(`/auth/user/export`,
        {'data': this.promocodeUsers.map(element => element.email)})
        .then((response) => {
            console.log(response);
            this.createFile(response, this.promocode.code);
          });
      }else{
        let search =
        {
          "search": {
            "value": this.search,
          }
        };
        await axiosInstance.post(`/users/search?limit=1000`, search)
        .then((response) => {
            axiosInstance.post(`/auth/user/export`,
              {'data': response.data.data.map(element => element.email)})
              .then((subresponse) => {
                  this.createFile(subresponse);
                });
        });
      }
    },
    async onSelectPromocode(value){

      if(value){
        this.promocode = this.promocodes.filter(element => { return element.id == value})[0];
        this.promocodeUsers = (await axiosInstance.get(`/promocodes/${value}/get-users`)).data.data;
        if(this.promocodeUsers.length)
        {
          this.filter = [
            {
              "field" : "id",
              "operator" : "in",
              "value" : this.promocodeUsers.map(element => element.id),
            }
          ];
          this.selectedPromocode = true;
        }
      }
    }
  },
  watch: {
    only_trashed: {
      handler() {
        this.get();
      }
    },
    search: {
      handler(newSearch, oldSearch) {
        newSearch != oldSearch ? this.options.page = 1 : '';
        this.get();
      }
    },
    filter: {
      handler() {
        this.search = null;
        this.get();
      }
    },
    options: {
      handler() {
        this.get()
      },
      deep: true,
    },
  },
}
</script>
