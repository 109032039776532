<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row justify="space-between">
          <v-col cols="12" lg="4">
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              label="Поиск..."
              filled
              background-color="transparent"
              hide-details
              name="title"
            >
              <v-btn v-if="search" class="reset-button"
                     slot="append"
                     icon
                     @click="search = null"
                     color="red"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-text-field>
          </v-col>
          <v-col cols="12" lg="4" class="justify-end align-center d-flex">
            <v-dialog v-model="dialog" persistent max-width="700px">
              <template v-slot:activator="{ on }">
                <v-btn color="primary" to="/library/create">
                  Создать
                </v-btn>
                <v-btn color="primary" class="ml-2" :href="getExportUrl">
                  Выгрузить
                </v-btn>
              </template>
            </v-dialog>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <v-row align="center">
              <v-col class="pr-0">
                <v-select
                  v-if="statuses"
                  v-model="query.status"
                  :items="statuses"
                  label="Выберите статус"
                  name="status"
                  prepend-icon="mdi-clipboard-pulse-outline"
                ></v-select>
              </v-col>
              <v-col cols="auto" class="pl-0">
                <v-btn
                  icon
                  @click="query.status = null"
                  color="red"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="3">
            <v-row align="center">
              <v-col class="pr-0">
                <v-select
                  v-if="formats"
                  v-model="query.format"
                  :items="formats"
                  label="Выберите формат"
                  name="format"
                  prepend-icon="mdi-format-text-variant-outline"
                ></v-select>
              </v-col>
              <v-col cols="auto" class="pl-0">
                <v-btn
                  icon
                  @click="query.format = null"
                  color="red"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col>
            <v-row align="center">
              <v-col cols="auto" class="p-0">
                <v-icon>mdi-calendar</v-icon>
                От
              </v-col>
              <v-col class="pr-0">
                <v-datetime-picker
                  ref="published_at_from"
                  v-model="dateQuery.published_at_from"
                  scrollable
                  label="Дата публикации"
                  clear-text="Очистить"
                ></v-datetime-picker>
              </v-col>
              <v-col cols="auto" class="pl-0">
                <v-btn
                  icon
                  @click="dateQuery.published_at_from = null"
                  color="red"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="auto">по</v-col>
              <v-col class="pr-0">
                <v-datetime-picker
                  ref="published_at_to"
                  v-model="dateQuery.published_at_to"
                  scrollable
                  label="Дата публикации"
                  clear-text="Очистить"
                ></v-datetime-picker>
              </v-col>
              <v-col cols="auto" class="pl-0">
                <v-btn
                  icon
                  @click="dateQuery.published_at_to = null"
                  color="red"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="auto">
            <v-row align="center">
              <v-col cols="12" class="pt-0">
                <v-switch
                  v-model="only_trashed"
                  label="Показать удаленные"
                  name="only_trashed"
                  value="1"
                ></v-switch>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="auto">
            <v-row align="center">
              <v-col cols="12" class="pt-0">
                <v-switch
                  v-model="published"
                  label="Показать опубликованные"
                  name="published"
                  value="1"
                ></v-switch>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="items"
              :options.sync="options"
              :loading="loading"
              :server-items-length="total"
              :search="search"
              :footer-props="{'items-per-page-options':[5, 10, 15, 20, -1]}"
            >
              <template v-slot:item.id="{ item }">
                <v-chip pill>{{ item.id }}</v-chip>
              </template>
              <template v-slot:item.status="{ item }">
                <v-chip :color="getStatusColor(item.status)" :content="item.human_status" label>{{ item.human_status }}</v-chip>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn
                  :href="editItem(item)"
                  icon
                >
                  <v-icon color="primary" small>
                    mdi-pencil
                  </v-icon>
                </v-btn>
                <v-btn
                  icon
                  @click="copyItem(item)"
                >
                  <v-icon color="primary" small>
                    mdi-content-copy
                  </v-icon>
                </v-btn>
                <v-btn
                  icon
                  :href="`${apiUrl}/api/module/moi-financy/library/${item.id}/export`"
                >
                  <v-icon color="primary" small>
                    mdi-file-download-outline
                  </v-icon>
                </v-btn>
                <v-btn
                  icon
                    target="_blank"
                  :href="item.preview_url"
                >
                  <v-icon color="primary" small>
                    mdi-eye
                  </v-icon>
                </v-btn>
              </template>
              <template v-slot:item.delete="{ item }">
                <v-icon
                  v-if="item.deleted_at"
                  color="green"
                  @click="restoreItem(item)"
                  small
                >
                  mdi-backup-restore
                </v-icon>
                <v-icon
                  v-else
                  color="red"
                  @click="deleteItem(item)"
                  small
                >
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {Library} from '../../../models/library';
import axiosInstance from '../../../axiosInstance';
import moment from "moment";

export default {
  name: 'Index',
  data: () => ({
    loading: true,
    total: 0,
    options: {
      sortBy: ['id'],
      sortDesc: [true],
      page: 1,
      itemsPerPage: 20
    },
    search: '',
    only_trashed: null,
    published: null,
    headers: [
      {text: 'Id', value: 'id'},
      {text: 'Название', value: 'title'},
      {text: 'Slug', value: 'slug'},
      {text:  'Создатель', value: 'creator', sortable: false},
      {text: 'Основная рубрика', value: 'main_taxonomy.title'},
      {text: 'Статус', value: 'status'},
      {text: 'Дата создания', value: 'created_at'},
      {text: 'Дата обновления', value: 'updated_at'},
      {text: 'Дата публикации', value: 'published_at'},
      {text: '', value: 'actions'},
      {text: '', value: 'delete'},
    ],
    items: [],
    query: {
      status: null,
      format: null,
    },
    dateQuery: {
      published_at_from: null,
      published_at_to: null,
    },
    statuses: [
      {text: 'Черновик', value: 0},
      {text: 'Запланировано', value: 1},
      {text: 'Опубликовано', value: 2},
      {text: 'Неактуальный', value: 3},
    ],
    formats: [
      {text: 'аудиозапись', value: 0},
      {text: 'брошюра', value: 1},
      {text: 'буклет', value: 2},
      {text: 'видеоролик', value: 3},
      {text: 'видеоурок', value: 4},
      {text: 'игра / квест', value: 5},
      {text: 'комплект материалов', value: 6},
      {text: 'материалы для бесед', value: 7},
      {text: 'мультфильм', value: 8},
      {text: 'материалы для работы', value: 9},
      {text: 'методические материалы', value: 10},
      {text: 'настольная игра', value: 11},
      {text: 'образовательный модуль', value: 12},
      {text: 'лекция / онлайн-курс', value: 13},
      {text: 'памятка', value: 14},
      {text: 'плакат', value: 15},
      {text: 'презентация', value: 16},
      {text: 'программа курса', value: 17},
      {text: 'рабочая тетрадь', value: 18},
      {text: 'сериал', value: 19},
      {text: 'разъяснения', value: 20},
      {text: 'статья', value: 21},
      {text: 'учебник', value: 22},
      {text: 'учебное пособие', value: 23},
      {text: 'фильм', value: 24},
    ],
    apiUrl: process.env.VUE_APP_API_URL,
    page: {
      title: "Библиотека"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Библиотека",
        disabled: true,
        to: '/library'
      },
    ],
  }),
  created() {
    this.get();
  },
  computed: {
    filters() {
      const filters = [];

      Object.keys(this.query).map((key) => {
        if (this.query[key] !== null) {
          filters.push({field: key, operator: '=', value: this.query[key]});
        }
      });

      this.dateQuery.published_at_from ? filters.push({
        field: 'published_at',
        operator: '>=',
        value: this.dateQuery.published_at_from
      }) : null;
      this.dateQuery.published_at_to ? filters.push({
        field: 'published_at',
        operator: '<=',
        value: this.dateQuery.published_at_to
      }) : null;
      this.published ? filters.push({field: 'status', operator: '=', value: 2}) : filters.push({
        field: 'status',
        operator: '!=',
        value: 2
      });

      return filters;
    },
    getExportUrl() {
      const payload = {
        title: this.search,
        status: this.query.status,
        format: this.query.format,
        published_at_from: this.dateQuery.published_at_from,
        published_at_to: this.dateQuery.published_at_to,
      };

      return process.env.VUE_APP_API_URL + '/api/module/moi-financy/library/export?' + (new URLSearchParams(payload).toString());
    },
  },
  methods: {
    editItem(item) {
      return this.$router.resolve({name: 'library-edit', params: {id: item.id}}).href
    },
    async deleteItem(item) {
      this.$confirm('Вы уверены?', 'Удаление').then(() => {
        Library.$query().destroy(item.id, 0);
        this.get();
      });
    },
    async restoreItem(item) {
      await Library.$query().restore(item.id);
      await this.get();
    },
    async copyItem(item) {
      await axiosInstance.get(`/module/moi-financy/library/${item.id}/make-copy`);
      await this.get();
    },
    async get() {
      this.loading = true;
      const {sortBy, sortDesc, page, itemsPerPage} = this.options;

      const data = {};
      this.filters ? data.filters = this.filters : null;
      this.search ? data.search = {value: this.search} : null;
      sortBy && sortBy[0] ? data.sort = [{field: sortBy[0], direction: sortDesc[0] ? 'desc' : 'asc'}] : null;
      const onlyTrashed = this.only_trashed ? `&only_trashed=1` : '';

      await axiosInstance.post(`/module/moi-financy/library/search?limit=${itemsPerPage}&page=${page}${onlyTrashed}`, data)
        .then((response) => {
          this.items = response.data.data;
          this.total = response.data.meta.total;
        });

      this.loading = false;
    },
    getStatusColor(status) {
      switch (status) {
        case 0:
          return 'red';
        case 1:
          return 'yellow';
        case 2:
          return 'green';
        default:
          return 'grey';
      }
    },
  },
  watch: {
    search: {
      handler(newSearch, oldSearch) {
        newSearch != oldSearch ? this.options.page = 1 : '';
        this.get();
      }
    },
    only_trashed: {
      handler() {
        this.get();
      }
    },
    published: {
      handler() {
        this.get();
      }
    },
    query: {
      handler() {
        this.options.page = 1;

        this.get();
      },
      deep: true,
      immediate: true,
    },
    dateQuery: {
      handler() {
        this.dateQuery.published_at_from = this.dateQuery.published_at_from
          ? moment(this.dateQuery.published_at_from).format('YYYY-MM-DD HH:mm')
          : this.$refs.published_at_from.date = null;
        this.dateQuery.published_at_to = this.dateQuery.published_at_to
          ? moment(this.dateQuery.published_at_to).format('YYYY-MM-DD HH:mm')
          : this.$refs.published_at_to.date = null;
        this.get();
      },
      deep: true,
    },
    options: {
      handler() {
        this.get()
      },
      deep: true,
    },
  }
};
</script>
