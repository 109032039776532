import {Model} from "@tailflow/laravel-orion/lib/model";

export class Review extends Model<{
    user_id: number,
    entity_id: number,
    entity_type: string,
    type_id: number,
    text: string,
    created_at: string,
    updated_at: string,
    published: boolean
}> {

    $resource(): string {
        return "reviews";
    }
}
