export default {
  namespaced: true,
  state: {
    color: "green",
    text: "",
    timeout: 3000,
    isVisible: false,
  },
  getters: {
    getColor: state => state.color,
    getText: state => state.text,
    getTimeout: state => state.timeout,
    isVisible: state => state.isVisible,
  },
  mutations: {
    setColor(state, color) {
      state.color = color;
    },
    setText(state, text) {
      state.text = text;
    },
    setTimeout(state, timeout) {
      state.timeout = timeout;
    },
    setIsVisible(state, value) {
      state.isVisible = value;
    }
  },
  actions: {
    showSnackbar({ commit }, { color, text, timeout = 3000 }) {
      commit('setColor', color);
      commit('setText', text);
      commit('setIsVisible', true);
      commit('setTimeout', timeout);
    },
    showSaveError({ dispatch }) {
      dispatch('showSnackbar', { color: 'red', text: 'При сохранении произошла ошибка!' });
    },
    showSuccessfullySaved({ dispatch }) {
      dispatch('showSnackbar', { color: 'green', text: 'Успешно сохранено!' });
    },
    showDeleteError({ dispatch }) {
      dispatch('showSnackbar', { color: 'red', text: 'При удалении произошла ошибка!' });
    },
    showSuccessfullyDelete({ dispatch }) {
      dispatch('showSnackbar', { color: 'green', text: 'Успешно удалено!' });
    },
    showSuccessfullyRestore({ dispatch }) {
      dispatch('showSnackbar', { color: 'green', text: 'Успешно восстановлено!'});
    },
    showErrorRestore({ dispatch }) {
      dispatch('showSnackbar', { color: 'red', text: 'При восстановлении элемента произошла ошибка!' });
    },
    showUnknownError({ dispatch} ) {
      dispatch('showSnackbar', { color: 'red', text: 'На странице произошла ошибка.' });
    },
    showFileUploaded({ dispatch }) {
      dispatch('showSnackbar', { color: 'green', text: 'Файл загружен успешно!' });
    },
    showFileError({ dispatch }) {
      dispatch('showSnackbar', { color: 'red', text: 'При загрузке файла произошла ошибка' });
    },
    showValidationError({ dispatch }) {
      dispatch('showSnackbar', { color: 'red', text: 'Заполните все обязательные поля' });
    },
    showSuccessBookmarked({ dispatch }) {
      dispatch('showSnackbar', { color: 'green', text: 'Отмечено!' });
    },
    showSuccessDeleteBookmarked({ dispatch }) {
      dispatch('showSnackbar', { color: 'green', text: 'Убрано из избранного!' });
    },
    showSuccessCopy({ dispatch }) {
      dispatch('showSnackbar', { color: 'green', text: 'Успешно скопировано! Проверьте скопированый элемент в конце списка' });
    },
    showErrorCopy({ dispatch }) {
      dispatch('showSnackbar', { color: 'red', text: 'При копировании произошла ошибка' });
    },
    showSuccessCopyShortUrl({ dispatch }) {
      dispatch('showSnackbar', { color: 'green', text: 'Коротка ссылка успешно скопирована в буфер обмена' });
    },
    showSuccessCopyShareText({ dispatch }) {
      dispatch('showSnackbar', { color: 'green', text: 'Код для шаринга успешно скопирована в буфер обмена' });
    },
    showSuccessCopyFastLoginUserUrl({ dispatch }) {
      dispatch('showSnackbar', { color: 'green', text: 'URL для авторизации скопирован' });
    },
    showSuccessCopyFastLoginUserUrlError({ dispatch }) {
      dispatch('showSnackbar', { color: 'red', text: 'Ошибка при копировании URL для авторизации' });
    },
  }
}
