<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row justify="space-between">
          <v-col cols="12" lg="4">
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              label="Поиск..."
              filled
              background-color="transparent"
              hide-details
              name="title"
            >
              <v-btn v-if="search" class="reset-button"
                     slot="append"
                     icon
                     @click="search = null"
                     color="red"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-text-field>
          </v-col>
          <v-col>
            <v-switch
              v-model="only_trashed"
              label="Показать удаленные"
              name="only_trashed"
              value="1"
            ></v-switch>
          </v-col>
          <v-col cols="12" lg="4" class="justify-end align-center d-flex">
            <v-dialog v-model="dialog" persistent max-width="700px">
              <template v-slot:activator="{ on }">
                <v-btn color="primary" to="/courses/create">
                  Создать курс
                </v-btn>
              </template>
            </v-dialog>
            <v-btn color="primary" class="ml-2" :href="getExportUrl">
              Выгрузить
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <v-row align="center">
              <v-col class="pr-0">
                <v-autocomplete
                  v-if="persons"
                  v-model="query.person_id"
                  :items="persons"
                  item-text="full_name"
                  item-value="id"
                  label="Выберите персону"
                  name="person_id"
                  required
                  class="mr-0"
                  prepend-icon="mdi-account-box"
                ></v-autocomplete>
              </v-col>
              <v-col cols="auto" class="pl-0">
                <v-btn
                  icon
                  @click="query.person_id = null"
                  color="red"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="items"
              :search="search"
              :options.sync="options"
              :server-items-length="total"
              :footer-props="{'items-per-page-options':[5, 10, 15, 20, -1]}"
            >
              <template v-slot:item.id="{ item }">
                <v-chip pill>{{ item.id }}</v-chip>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-if="getUrlWithLastSlash(item.preview_url)"
                      :href="getUrlWithLastSlash(item.preview_url) + '?preview_mode=true'"
                      target="_blank"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon color="primary" small>
                        mdi-eye
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Предпросмотр курса</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-if="item.admin_attach_url"
                      v-bind="attrs"
                      v-on="on"
                      @click="attachItem(item)"
                    >
                      <v-icon color="primary" small>
                        mdi-eye-plus
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Предпросмотр купленного курса</span>
                </v-tooltip>
                <v-btn
                  :href="editItem(item)"
                  icon
                >
                  <v-icon color="primary" small>
                    mdi-pencil
                  </v-icon>
                </v-btn>
                <v-icon
                  class="mr-2"
                  color="primary"
                  @click="copyItem(item)"
                  small
                >
                  mdi-content-copy
                </v-icon>
                <v-icon
                  class="mr-2"
                  color="primary"
                  @click="lessons(item)"
                  small
                >
                  mdi-clipboard-list
                </v-icon>
                <v-icon
                  v-if="item.deleted_at"
                  color="green"
                  @click="restoreItem(item)"
                  small
                >
                  mdi-backup-restore
                </v-icon>
                <v-icon
                  v-else
                  color="red"
                  @click="deleteItem(item)"
                  small
                >
                  mdi-delete
                </v-icon>
                <v-icon
                  class="ml-2"
                  @click="pickupItem(item)"
                  small
                >
                  mdi-arrow-up
                </v-icon>
              </template>
              <template v-slot:item.publish="{ item }">
                <v-chip :color="getStatusColor(item.status)" :content="item.human_status" label>{{ item.human_status }}</v-chip>
              </template>
              <template v-slot:item.publish_eu="{ item }">
                <v-chip :color="getStatusColor(item.status_eu)" :content="item.human_status_eu" label>{{ item.human_status_eu }}</v-chip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import axiosInstance from "../../../axiosInstance";
import {mapActions, mapGetters} from "vuex";
import urlMixin from "@/mixins/urlMixin";

export default {
  name: 'Index',
  mixins: [urlMixin],
  data: () => ({
    search: '',
    only_trashed: null,
    options: {
      sortBy: ['pickup_at'],
      sortDesc: [true],
      page: 1,
      itemsPerPage: 20,
    },
    query: {
      person_id: null,
    },
    headers: [
      {text: 'Id', value: 'id'},
      {text: 'Название', value: 'title'},
      {text: 'Slug', value: 'slug'},
      {text: 'Статус', value: 'publish'},
      {text: 'Статус EU', value: 'publish_eu'},
      {text: 'Дата создания', value: 'created_at'},
      {text: 'Дата обновления', value: 'updated_at'},
      {text: '', value: 'actions'},
    ],
    page: {
      title: "Курсы",
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Курсы",
        disabled: true,
        to: '/courses/'
      },
    ],
  }),
  created() {
    this.get();
    this.getPersons();
  },
  computed: {
    ...mapGetters({
      items: 'Courses/getFetchedListing',
      total: 'Courses/getListingTotalItems',
      persons: 'Persons/getListingWithoutFilters',
    }),
    getExportUrl(){
      return process.env.VUE_APP_API_URL + '/api/module/courses/export';
    },
    filters() {
      const filters = [];

      Object.keys(this.query).map((key) => {
        if (this.query[key]) {
          filters.push({field: key, operator: '=', value: this.query[key]});
        }
      });

      return filters;
    },
  },
  methods: {
    ...mapActions({
      fetchListing: 'Courses/fetchListing',
      fetchPersons: 'Persons/fetchListingWithoutFilters',
      restore: 'Courses/restoreItem',
      pickup: 'Courses/pickupItem',
      destroy: 'Courses/destroyItem'
    }),
    lessons(item) {
      return this.$router.push({name: 'lessons-index', params: {course_id: item.id}})
    },
    editItem(item) {
      return this.$router.resolve({name: 'courses-edit', params: {id: item.id}}).href
    },
    deleteItem(item) {
      this.$confirm('Вы уверены?', 'Удаление').then(() => {
        this.destroy(item).then(() => {
          this.get();
        })
      });
    },
    async attachItem(item) {
      axiosInstance.get(`/auth/user/course/${item.id}/attach`)
        .then(({ data }) => {
          const url = new URL(data);
          url.pathname = `/lk/courses/${item.slug}/`;
          url.search = '?preview_mode=true';

          window.open(url.toString());
        });
    },
    async pickupItem(item) {
      this.pickup(item).then(() => {
        this.get();
      })
    },
    async restoreItem(item) {
      this.restore(item).then(() => {
        this.get();
      })
    },
    async copyItem(item) {
      await axiosInstance.get(`/module/courses/${item.id}/make-copy`);
      await this.get();
    },
    async get() {
      const payload = {
        options: this.options,
        only_trashed: this.only_trashed,
        search: this.search,
        filters: this.filters,
      }

      this.fetchListing(payload);
    },
    async getPersons() {
      this.fetchPersons();
    },
    getStatusColor(status) {
      switch (status) {
        case 0:
          return 'red';
        case 1:
          return 'green';
        default:
          return 'grey';
      }
    },
  },
  watch: {
    only_trashed: {
      handler() {
        this.get();
      }
    },
    search: {
      handler(newSearch, oldSearch) {
        newSearch != oldSearch ? this.options.page = 1 : '';
        this.get();
      }
    },
    options: {
      handler() {
        this.get()
      },
      deep: true,
    },
    query: {
      handler() {
        this.options.page = 1

        this.get();
      },
      deep: true,
    }
  },
};
</script>

<style scoped>

</style>
