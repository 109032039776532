<template>
  <v-container class="down-top-padding" fluid>
    <BaseBreadcrumb :breadcrumbs="breadcrumbs" :icon="page.icon" :title="page.title"/>
    <v-card>
      <v-card-text class="pa-5">
        <v-row justify="space-between">
          <v-col cols="12" lg="4">
            <v-text-field
              v-model="search"
              background-color="transparent"
              filled
              hide-details
              label="Поиск..."
              name="title"
              prepend-inner-icon="mdi-magnify"
            >
              <v-btn
                v-if="search"
                slot="append"
                class="reset-button"
                color="red"
                icon
                @click="search = null"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-text-field>
          </v-col>
          <v-col>
            <v-switch
              v-model="only_trashed"
              label="Показать удаленные"
              name="only_trashed"
              value="1"
            />
          </v-col>
          <v-col class="justify-end align-center d-flex" cols="12" lg="4">
            <v-dialog max-width="700px" persistent>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" to="/reviews/create">
                  Создать отзыв
                </v-btn>
              </template>
            </v-dialog>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="items"
              :loading="loading"
              :options.sync="options"
              :search="search"
              :server-items-length="total"
            >
              <template v-slot:item.id="{ item }">
                <v-chip pill>{{ item.id }}</v-chip>
              </template>
              <template v-slot:item.published="{ item }">
                <span>{{ item.published ? 'Да' : 'Нет' }}</span>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon v-if="item.deleted_at" color="green" small @click="restoreItem(item)">
                  mdi-undo
                </v-icon>
                <template v-else>
                  <v-btn :href="editItem(item)" icon>
                    <v-icon color="primary" small>
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                  <v-icon color="red" small @click="deleteItem(item)">
                    mdi-delete
                  </v-icon>
                </template>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {Review} from "@/models/review";
import axiosInstance from "@/axiosInstance";
import BaseBreadcrumb from "@/components/commonComponents/BaseBreadcrumb.vue";

export default {
  name: 'Index',
  components: {BaseBreadcrumb},
  data: () => ({
    loading: true,
    total: 0,
    options: {
      sortBy: ['id'],
      sortDesc: [true],
    },
    search: '',
    only_trashed: false,
    headers: [
      {text: 'Id', value: 'id'},
      {text: 'Пользователь', value: 'user.email', sortable: false},
      {text: 'Текст', value: 'text'},
      {text: 'Опубликовано', value: 'published'},
      {text: 'Дата создания', value: 'created_at'},
      {text: 'Дата обновления', value: 'updated_at'},
      {text: '', value: 'actions'},
    ],
    items: [],
    page: {
      title: "Отзывы"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Отзывы",
        disabled: true,
        to: '/reviews'
      },
    ],
  }),
  created() {
    this.get();
  },
  methods: {
    editItem(item) {
      return this.$router.resolve({name: 'reviews-edit', params: {id: item.id}}).href
    },
    async deleteItem(item) {
      this.$confirm('Вы уверены?', 'Удаление').then(async () => {
        await Review.$query().destroy(item.id, 0);
        await this.get();
      });
    },
    async restoreItem(item) {
      await Review.$query().restore(item.id);
      await this.get();
    },
    async get() {
      this.loading = true;
      const {sortBy, sortDesc, page, itemsPerPage} = this.options;
      const onlyTrashed = this.only_trashed ? `&only_trashed=1` : '';
      const data = {};
      this.search ? data.search = {value: this.search} : null;
      sortBy && sortBy[0] ? data.sort = [{field: sortBy[0], direction: sortDesc[0] ? 'desc' : 'asc'}] : null;

      await axiosInstance.post(`/reviews/search?limit=${itemsPerPage}&page=${page}${onlyTrashed}`, data)
        .then((response) => {
          this.items = response.data.data;
          this.total = response.data.meta.total;
        });

      this.loading = false;
    },
  },
  watch: {
    search: {
      handler() {
        this.get();
      }
    },
    options: {
      handler() {
        this.get()
      },
      deep: true,
    },
    only_trashed: {
      handler() {
        this.get()
      },
      deep: true,
    },
  },
};
</script>
